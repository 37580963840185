<template>
  <div id="app">
    <div class="navbar">
      <router-link to="/">
        <img alt="Vue logo" src="./assets/pizzaLogo.svg" />
      </router-link>
    </div>
    <div id="content">
      <router-view />
    </div>
    <div class="footer">
      <router-link to="/imprint"> About this Website </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
#app {
  font-family: monospace, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #181818;
  margin-top: 60px;
}
.navbar {
  overflow: hidden;
  position: fixed;
  text-align: left;
  top: 0;
  width: 100%;
  background: white;
}
.navbar a img {
  width: 50px;
}
#content {
  padding-bottom: 50px;
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 10px;
  width: 95%;
  color: black;
  background: white;
  text-align: right;
}
.footer a {
  color: #181818;
  text-decoration: none;
}
</style>
