<template>
  <div id="home">
    <img alt="Vue logo" src="../assets/pizzaLogo.svg" />
    <h1>
      Pizza Dough Calculator
    </h1>
    <span>
      <a
        href="https://apps.apple.com/us/app/the-pizza-dough-calculator/id1561750920"
        target="_blank"
      >
        <img alt="Vue logo" src="../assets/appStoreLogo.svg" />
      </a>
      <a
        href="
      https://play.google.com/store/apps/details?id=com.pizza.dough.calculator"
        target="_blank"
      >
        <img alt="Vue logo" src="../assets/playStoreLogo2.svg" />
      </a>
    </span>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>

<style>
#home img {
  width: 100%;
  max-width: 400px;
  height: auto;
}
@media (max-width: 480px) {
  #home img {
    max-width: 150px;
  }
}
#home span img {
  width: 15%;
  min-width: 220px;
  height: auto;
  padding: 2%;
}
</style>
